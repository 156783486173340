.custom-rating {
  height: 20px;
}

.custom-rating svg {
  width: 20px;
  height: 20px;
}

.custom-rating span {
  width: 20px;
  height: 20px;
}
